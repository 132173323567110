.detail-card {
  background-color: var(--tableBackgroud) !important;
  border: 1px solid var(--borderCard);
}

.detail-card > .card-body {
  padding: 30px 30px 0 3vw;
}

.detail-card > .card-body:hover {
  overflow: auto;
}

.button-group-card {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button-group-card > button {
  margin-left: 20px;
}

.detail-card > .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--mainColor);
  height: 65px;
  padding: 0 30px 0 3vw;
  font-family: "Source Sans Pro";
  border-radius: 0px;
}

.dark .detail-card > .card-header {
  background-color: var(--accentColor);
}

.card-header.flex {
  height: auto;
}

.card-header > h5 {
  color: var(--inverseText);
  padding-top: 10px;
}

.close-button {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 20px;
  height: 20px;
  border-radius: 24px;
  color: var(--mainColor);
  background-color: var(--primaryBackgroundColor);
}

.dark .close-button {
  background-color: var(--mainColor);
  color: var(--accentColor);
}

.body-title {
  color:var(--mainColor);
  font-family: "Source Sans Pro";
}

.detail-card-section {
  color: var(--primaryText);
  display: flex;
  width: auto;
  flex-direction: column;
  align-items: flex-start;
}
