.app .ReactModal__Overlay {
  opacity: 0;
  transition: opacity 2000ms ease-in-out;
}

.app .ReactModal__Overlay--after-open{
  opacity: 1;
}

.app .ReactModal__Overlay--before-close{
  opacity: 0;
}

.modal-content {
  background-color: var(--primaryBackgroundColor);
  color: var(--primaryText);
  padding: 0 0 0 0;
}

.modal-header {
  font-family: "IBM Plex Sans";
  border: 0px;
  padding: 30px 40px 20px 40px;
}
.modal-header .close-button,
.modal-header > * {
  color: var(--modalHeaderColor);
}

.modal-header .close-button,
.modal-header .fullscreen-button {
  position: absolute;
  float: right;
  top: 30px;
  font-size: 1.3rem;
  font-weight: 200;
}

.modal-header .close-button {
  right: 40px;
}

.modal-header .fullscreen-button {
  right: 80px;
}

.fullscreen-button {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 20px;
  height: 20px;
  border-radius: 24px;
  color: var(--mainColor);
}

.modal-divider {
  margin: 0 40px;
  border-bottom: 1px solid var(--modalHeaderColor);
}

.modal-body {
  padding: 30px 25px;
  max-height: 75vh;
  overflow: auto;
}

.modal-footer {
  border: 0px;
}
