
.top-bar {
  top: 0;
  display: flex;
  flex-direction: row;
  justify-Content: space-between;
  alignItems: center;
  width: 100%;
  height: 56px;
  border-Bottom: 1px solid #d8d8d8;
  font-Weight: bold;
  padding: 0 16px 14px 16px;
  box-Sizing: border-box;
  z-index: 3;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  font-family: "IBM Plex Sans";
}
.top-bar a i,
.top-bar {
  background-Color: var(--headerBackground);
  color: var(--mainNavActiveTextColor);
}

.navigation-icon {
  width: 24px;
  height: 24px;
  margin: 0 32px 0 0;
}

.top-bar .title {
  width:100%;
  vertical-align: 18px;
  margin-block-start: 20px;
}

.action-items {
  width: 24px;
  height: 24px;
  margin: 0 0 0 24px;
}

.overflow-menu {
  width: 24px;
  height: 24px;
  margin: 0 0 2px 24px;
}

.container-left {
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0 0 0 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 0px 0 0px 0;
}

.container-left h6 {
  margin-bottom: 4px;
  color: var(--mainNavActiveTextColor);
}

.container-right {
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0 0 0 0;
  display: flex;
  flex-direction: row;
  width: min-content;
  height: 100%;
  padding: 0px 0 0px 0;
}

