.nav-tabs.flex-column {
  border-right: 1px solid #dee2e6;
  border-bottom: none;
  height: 100%;
  .nav-item {
    margin-right: -1px;
    margin-bottom: 0;
    cursor: pointer;
  }
  .nav-link {
    border: none;
  }
  .nav-link.active {
    border-right: 3px solid var(--accentColor);
    border-radius: 0;
    background-color: transparent;
    font-weight: bold;
  }
  a.nav-link {
    width: 100%;
    color: var(--primaryText);
  }
}
