.dynamic-form {
  .bg-primary {
    background-color: var(--mainColor) !important;
  }
  .text-primary {
    color: var(--mainColor) !important;
  }
  .working-indicator {
    position: relative;

    .spinner-grow,
    .spinner-border {
      position: absolute;
      right: 0;
    }
  }
}
