.bottom-navigation {
  display: flex;
  grid-area: bottomNavigation;
  alignItems: stretch;
  width: 100%;
  height: 56px;
  border-top: 1px solid var(--mainColor);
  position: relative;
  bottom: 0;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  font-family: "IBM Plex Sans";
  z-index: 1;
}

.bottom-navigation {
  background-color: var(--lightMainColor);
  color: var(--primaryText);
}

.bottom-navigation ul {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;;
  padding: 8px 0 12px 0;
  margin:  0 0 0 0;
  justify-content: center;

}

.bottom-navigation li.nav-item {
  display: flex;
  flex-direction: column;
  width:100%;
  max-width: 168px;
  min-width: 80px;
  align-items: center;
}

.bottom-navigation li.nav-item i {
  width: 24px;
  font-size: 1rem;
  text-align: center;
}

.bottom-navigation li.nav-item label {
  font-size: 1rem;
}

.bottom-navigation li.active label,
.bottom-navigation li.active {
  color: var(--bottomNavigationActive);
}

@media only screen and (max-width: 768px) {
  .bottom-navigation {
    display:flex;
  }
}
