/* Date field */
.custom-date-box.dx-texteditor.dx-state-focused {
    border-color: var(--lightAccentColor) !important;
}
.custom-date-box.dx-texteditor,
.custom-date-box.dx-texteditor.dx-state-hover {
    background-color: var(--lightMainColor);
    border-color: var(--fieldBorder);
}

/* Date field icons */
.custom-date-box .dx-dropdowneditor-icon,
.custom-date-box .dx-icon-clear:hover {
    color: var(--primaryText) !important;
}
.custom-date-box.dx-texteditor.dx-state-hover .dx-dropdowneditor-icon {
    background-color: var(--selectHighlightColor);
}
.dark .custom-date-box.dx-dropdowneditor-active .dx-dropdowneditor-icon,
.dark .custom-date-box.dx-state-active .dx-dropdowneditor-icon {
    background-color: var(--mainColor);
}


/* Dark theme overlay calendar */
.dark .dx-popup-wrapper > .dx-overlay-content {
    background-color: var(--mainBackground);
    border-color: var(--mainBackground);
}
.dark .dx-popup-wrapper .dx-datebox-datetime-time-side,
.dark .dx-popup-wrapper .dx-calendar {
    background-color: transparent;
}
.dark .dx-popup-wrapper .dx-button-mode-contained {
    background-color: var(--mainBackground);
}
/* Calendar cell */
.dark .dx-popup-wrapper .dx-calendar-cell {
    color: var(--primaryText);
    box-shadow: none;
}
.dark .dx-popup-wrapper .dx-calendar-cell.dx-calendar-other-view {
    color: var(--secondaryText);
}
.dark .dx-popup-wrapper .dx-calendar-cell.dx-calendar-contoured-date {
    background-color: var(--lightAccentColor) !important;
    color: var(--lightMainColor);
    box-shadow: none;
}
.dark .dx-popup-wrapper .dx-calendar-cell.dx-state-hover {
    background-color: var(--selectHighlightColor);
}
/* Calendar navigator */
.dark .dx-calendar-navigator .dx-button {
    border-color: var(--fieldBorder);
}
.dark .dx-calendar-navigator .dx-button .dx-button-content,
.dark .dx-calendar-navigator .dx-button .dx-icon {
    color: var(--primaryText);
}
.dark .dx-calendar-navigator .dx-button.dx-state-hover,
.dark .dx-calendar-navigator .dx-button.dx-state-active {
    border-color: var(--lightAccentColor);
}
/* Bottom buttons */
.dark .dx-popup-wrapper .dx-popup-bottom .dx-button {
    color: var(--primaryText);
    border-color: var(--fieldBorder);
}
.dark .dx-popup-wrapper .dx-popup-bottom .dx-button.dx-state-hover,
.dark .dx-popup-wrapper .dx-popup-bottom .dx-button.dx-state-active {
    border-color: var(--lightAccentColor);
}
/* Time fields */
.dark .dx-timeview-field .dx-texteditor {
    background-color: var(--lightMainColor);
    border-color: var(--fieldBorder);
}
.dark .dx-timeview-field .dx-texteditor .dx-texteditor-input,
.dark .dx-timeview-field .dx-numberbox-spin-button > div {
    color: var(--primaryText);
}
.dark .dx-timeview-field .dx-texteditor.dx-state-focused {
    border-color: var(--lightAccentColor);
}
.dark .dx-timeview-field .dx-numberbox-spin-button.dx-state-hover > div {
    box-shadow: none;
    background-color: var(--selectHighlightColor);
}