.checks{
    padding: 1rem;
    margin-left: 1rem;
    height: 250px;
    position: relative;
}
.checks .content-checks{
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
}
.checks .content-checks p{
    margin-left: 8px;
    margin-bottom: 0.1rem;
    margin-top: 0.4rem;
}
.list-option{
    min-height: 5rem;
    overflow: auto;
}
