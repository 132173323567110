.right-bar {
    display: none;
}

/* desktop */
@media only screen and (min-width: 65.875em) {
    .right-bar {
        position: absolute;
        display: flex;
        right: 0px;
        height: 100vh;
        background-color: transparent;
        z-index: 3;
    }

    .right-bar .right-bar-border {
        border-left: var(--mainColor) solid 2px;
        width: 2px;
        height: 100%;
    }

    .right-bar .right-bar-container {
        position: relative;
        right: 320px;
        display: none;
        background-color: var(--sideBackground);
        overflow-y: scroll;
    }

    .right-bar .right-bar-container::-webkit-scrollbar {
        display: none;
    }

    .right-bar .off-header-button {
        right: 0px;
    }

    .right-bar.anchor .right-bar-border,
    .right-bar:hover .right-bar-border {
        border-left: var(--sideBackground) solid 2px;
    }

    .right-bar.anchor .right-bar-container,
    .right-bar:hover .right-bar-container {
        border-left: var(--mainColor) solid 2px;
        display: block;
        background-color: var(--lightMainColor);
        width: 320px;
        height: 100%;
        padding: 15px;
    }

    .right-bar.anchor .off-header-button,
    .right-bar:hover .off-header-button {
        right: 320px;
    }

    .right-bar-container .input-search-issue {
        height: 100%;
        width: 100%;
        margin-top: 15px;
        margin-left: 0px;
    }

    .right-bar-container .css-1etonvt-control {
        margin-left: 0px;
    }

    .right-bar-container .header-item i {
        margin-right: 25px;
    }

    .right-bar-container .header-item.size-l {
        width: 85%;
    }

    .right-bar .search-input {
        width: 100% !important;
    }
    
    .right-bar-container .icons-container,
    .right-bar-container .search-container {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        align-items: center;
        margin-top: 15px;
    }

    .right-bar-container .icons-container {
        margin-top: 0px;
        direction: rtl;
    }

    .right-bar-container .icons-container .header-item i {
        margin-right: 0px;
        margin-left: 25px;
    }


    .right-bar-container .component-container {
        display: block;
        margin-top: 15px;
    }

    .right-bar-container .component-container button {
        margin-top: 15px;
        float: right;
    }

    .right-bar-container .header-item {
        color: var(--secondaryText);
    }

    .right-bar-container .search-milestone-button {
        margin-right: 0px;
    }


}
