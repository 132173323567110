.editable-map-wrapper {
  position: relative;
}

.editable-map {
  width: 100%;
  height: 100%;
}

.editable-map-overlay {
  position: absolute;
  bottom: 16px;
  left: 16px;
  z-index: 99;
}

.shape-editor {
  display: flex;
  flex-direction: column;
  background-color: var(--backgroundColor);
  border-radius: 1rem;
  box-shadow: 2px 2px 8px 2px #00000054;
  padding: 12px 16px;
}

.shape-editor-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.shape-editor-header h6 {
  margin-top: 4px;
}

.shape-editor-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.shape-editor-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.shape-editor .form-group {
  //margin-bottom: 0;
}

.shape-editor .opacity-slider {
  display: flex;
  height: 100%;
  align-items: center;
}

.opacity-slider .custom-range.form-control {
  border: none;
}

.jsoneditor-menu li {
  padding: 0;
  display: list-item;
}

.jsoneditor-tree {
  background-color: #FDF6E3;
}

.dark .jsoneditor-tree {
  background-color: var(--backgroundColor);
}

.dark .jsoneditor-field,
.dark .jsoneditor-value {
  color: var(--primaryText);
}

.dark .jsoneditor-value.jsoneditor-string {
  color: #73bb86;
}

.dark .jsoneditor-value.jsoneditor-number {
  color: #fb7363;
}

.dark .jsoneditor-value.jsoneditor-boolean {
  color: #ffc175;
}

.dark div.jsoneditor-field[contenteditable=true]:focus,
.dark div.jsoneditor-field[contenteditable=true]:hover,
.dark div.jsoneditor-value[contenteditable=true]:focus,
.dark div.jsoneditor-value[contenteditable=true]:hover,
.dark div.jsoneditor-field.jsoneditor-highlight,
.dark div.jsoneditor-value.jsoneditor-highlight {
  background-color: rgba(255,255,255,0.2);
  border: 1px solid #f4fcff;
}

.editable-map-wrapper .container-layers .btn i{
  margin: 0;
}
.editable-map-wrapper .container-layers {
  display: flex;
  justify-items: center;
  align-items: center;
}