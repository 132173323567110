.floatMenu{
  position: fixed;
  background-color: var(--headerBackground);
  left: 200px;
  z-index: 999;
  min-width: 150px;
  min-height: 50px;
}
.emptyLink{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
