.drag-file-box {
  border-radius: 8px;
  height: 15rem;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--accentColor);
  position: relative;
}

.drag-file-box.small {
  flex-direction: row;
  height: 40px;
}

.drag-file-box.small i {
  font-size: 1.5em;
  margin-top: 0;
}
.drag-file-box.small .info-text {
  margin-top: 0;
  margin-left: 10px;
  font-size: 1.1em;
}

.drag-file-box .custom-file {
  display: none;
}

.drag-file-box .fake-background {
  position:absolute;
  border: 2px dashed var(--accentColor);
  border-radius: 8px;
  /*background: var(--accentColor);*/
  opacity:0.4;
  width:100%;
  height: 100%;
}

.drag-file-box .fake-background:hover {
  border: 2px dashed var(--accentColor);
  opacity: 1;
}

.drag-file-box i {
  color: var(--accentColor);
  font-size: 2em;
  margin-top: 70px;
}

.drag-file-box .info-text {
  margin-top: 20px;
  font-size: 1.2rem;
  font-family: var(--font-family-sans-serif);
}

.file-list ul {
  list-style-type: none;
  padding: 0;
}

.file-list li {
 margin: 25px 0 5px 0;
}

.file-list .file-type-container {
  display: flex;
  flex-direction: row;
}

.file-list .circle {
  height: 55px;
  width: 55px;
  min-width: 55px;
  background-color: var(--backgroundColor);
  border-radius: 50%;
  display: block;
  margin-right: 20px;
  border:2px solid var(--accentColor);
  opacity: 0.8;
}

.file-list .circle .file-type-text {
  padding: 13px 0 0 0;
  font-size: 1rem;
  color: var(--accentColor);
  text-align: center;
}

.file-data {
  max-height: 60px;
  max-width: calc(100% - 55px - 20px);
}

.file-data .file-name {
  margin-top: 5px;
  opacity: 0.9;
}

.file-data .file-metadata {
  opacity: 0.6;
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
