
.table-container {
  background-color: var(--tableContainer);
}

.app .table {
  color: unset;
  margin-bottom: unset;
}

.app .tabulator,
.modal .tabulator {
  background-color: unset;
  border: none;
}

.app .tabulator .tabulator-header,
.modal .tabulator .tabulator-header {
  background-color: unset;
  color: var(--tableHeader);
}

.app .tabulator .tabulator-header .tabulator-col,
.modal .tabulator .tabulator-header .tabulator-col {
  background: none;
  border-right: none;
  /*border-right-color: rgba(255,255,255,0.12);*/
}

.app .tabulator .tabulator-tableHolder .tabulator-table,
.modal .tabulator .tabulator-tableHolder .tabulator-table {
  color: var(--tableText);
  background-color: unset;
}

.app .tabulator .tabulator-header .tabulator-col:hover,
.modal .tabulator .tabulator-header .tabulator-col:hover {
  background-color: rgba(255,255,255,0.2);
}

.app .tabulator-row,
.modal .tabulator-row {
  background-color: var(--tableStripe);
}

.app .tabulator-row:hover,
.app .tabulator-row.tabulator-selectable:hover,
.modal .tabulator-row:hover,
.modal .tabulator-row.tabulator-selectable:hover{
  background-color: var(--tableHover);
}

.app .tabulator-row.tabulator-row-even,
.modal .tabulator-row.tabulator-row-even {
  background-color: transparent;
}

.app .tabulator-row .tabulator-cell,
.modal .tabulator-row .tabulator-cell {
  border-right: none;
}

.btn-link.table-action {
  padding: 0;
  color: var(--secondaryText) !important;
}

.btn-link.table-action:hover {
  color: var(--accentColor) !important;
}
