.swatches-picker.custom-swatches-picker {
  >:first-child {
    display: block !important;

    >:first-child {
      position: absolute !important;
      box-shadow: none !important;
      background: none !important;
    }

    >:last-child {
      position: relative !important;

      >div {
        overflow-y: visible !important;

        >div {
          padding: 0 0 0 16px !important;
        }
      }
    }
  }
}

.sketch-picker.custom-sketch-picker {
  box-shadow: none !important;
  background: none !important;
  padding: 0 !important;

  .flexbox-fix {
    input {
      border: none !important;
      box-shadow: none !important;
    }

    input:active {
      border-color: var(--lightAccentColor) !important;
      box-shadow: none !important;
    }

    label {
      color: var(--primaryText) !important;
      width: 80%;
    }

    &:nth-child(2) {
      >div {
        padding: 10px 0px !important;
      }

      :nth-child(2) {
        width: 24px !important;
        height: 24px !important;
      }

    }

    &:nth-child(3) {
      border-bottom: 1px solid #ccc;
    }

    &:nth-child(4) {
      border-top: none !important;

      div {
        height: 24px !important;
        width: 24px !important;
      }
    }
  }
}