.dropdown-mobile .dropdown-menu {
  background-color: var(--lightMainColor);
  top: 15px !important;
  border-radius: 0;
  transform: translate3d(-20px, 21px, 0px) !important;
  width: 250px !important;
  max-height: 300px !important;
  overflow: auto;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.dropdown-mobile .dropdown-menu span,
.dropdown-mobile .dropdown-menu i {
  color: var(--primaryText);
  font-size: 1em;
}

.dropdown-mobile .dropdown-menu span {
  font-weight:lighter;
}
.dropdown-mobile .dropdown-menu i {
  margin: 15px 25px 0 0 !important;
}
