.hover-header {
  display: none;
}
/* desktop */
@media only screen and (min-width: 65.875em) {

  .pageheader {
    display: block;
  }

  .hover-header {
    display: block;
  }

  .pageheader .title,
  .pageheader h6 {
    margin-left: 30px;
  }
  .pageheader .title i {
    color: var(--mainColor);
    margin: 0 10px 0 0;
  }

  .hover-header {
    align-self: flex-start;
    position: absolute;
    display: flex;
    width: 100%;
    min-height: 15px;
    background-color: transparent;
    z-index: 3;
    margin-left: 0px;
  }

  .hover-header .hover-header-border {
    border-bottom: var(--mainColor) solid 2px;
    height: 2px;
    width: 100%;
  }

  .hover-header .header-items-container {
    position: relative;
    display: none;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--sideBackground);
    padding-right: 8px;
  }
  .hover-header.anchor .header-items-container {
    border-bottom: var(--mainColor) solid 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
  }

  .hover-header:hover .header-items-container {
    border-bottom: var(--mainColor) solid 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;

  }

  .sheet-mode .off-header-button {
    position: absolute;
    right: 23px;
    width: 36px;
    height: 32px;
    text-align: center;
    font-size: 1.3rem;
    background-color: var(--mainColor);
    color: var(--mainNavActiveTextColor);
  }

  .bar-container .header-item.active {
    color: var(--mainColor);
    opacity: 0.6;
  }

  .bar-container .header-item input {
    border: 0px;
  }

  .header-items-container .header-item.size-l {
    width: 350px;
  }

  .header-items-container .header-item {
    display: flex;
    align-items: center;
    width: 20px;
    height: 22px;
    color: var(--secondaryText);
    margin-right: 25px;
  }

  .bar-container .header-item i {
    font-size: 1.1rem;
  }

  .sheet-mode .search-input {
    background: var(--backgroundColor);
    border-radius: initial;
  }

  .input-search-wrapper {
    background: var(--backgroundColor);
    position: relative;
  }
  .search-input {
    display: inline !important;
    width:250px !important;
  }

}
