.miniapp {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 80px 1fr;
  grid-template-areas:
    "sidenav main";
  height: 200px;
  margin-top: 20px;
}

.miniapp > header {
  grid-area: header;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
}

.miniapp > nav {
  grid-area: sidenav;
  box-shadow: 4px 0 5px rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.miniapp > main {
  grid-area: main;
  display: flex;
}

.minibutton {
  margin: 8px;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
  height: 14px;
}

.minipanel {
  border-radius: 3px;
  background-color: white;
  margin: 8px 16px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
  height: 90px;
  width: 55%;
  display: flex;
  padding: 6px;
}

.minititle {
  width: 40px;
  height: 6px;
  font-size: 10%;
  font-weight: bold;
}
