.base-list  {
  width: 100%;
  font-family: "IBM Plex Sans";
  font-size: 0.8rem;
  z-index: 1;
}

.base-list:hover {
  z-index: 3;
}

.base-list .base-list-item {
  max-width: 100%;
  min-height: 60px;
  border: 1px solid var(--listItemBorder);
  border-bottom: none;
}
.base-list-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 40px;
  padding: 8px 18px;
}

.base-list-item .item-data-block {
  width: 100%;
  cursor: default;
  color: var(--secondaryText);
}

.base-list-item.active, .base-list .base-list-item:hover {
  background-color: var(--listItemHover);
  z-index: 3;
}

.base-list-content .base-list-item:first-of-type {
  margin-top: 1rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.base-list-content  .base-list-item:last-of-type {
  margin-bottom: 1rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom: 1px solid var(--listItemBorder);
}

.base-list-item .item-select {
  margin-right: 10px;
}
.item-name {
  width: 100%;
}

.item-data-block > a, .item-data-block > .item-name {
  display: block;
  cursor: default;
  color: var(--primaryText);
  cursor: pointer;
  text-decoration: none;
}

.base-list .badge-element{
  display: inline-block;
  font-size: 12px;
  border-radius: 4px;
}

.secondary-elements {
  margin: 0 20px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  width: 100%;
}
.secondary-elements > * {
  color: var(--secondaryText);
}

.base-list-item .menu {
  width: 32px;
  text-align: center;
  margin-right: 20px;
  color: var(--menuIconColor);
}

.form-check-input {
  position: relative;
}

.badge-circle-container{
  align-self: center
}
.badge-circle{
  display: block!important;
  width: 10px;
  height: 10px;
  border-radius: 10rem;
  font-size: 0!important;
}
.container-list-items{
  width: 100%;
}
.container-list-actions{
  justify-content: end;
  margin-right: 1rem;
  color: var(--listActionLink)!important;
}
.container-list-actions .btn {
  color: var(--listActionLink)!important;
}
@media screen and (max-width: 575px) {
  .container-list-actions{
    margin-top: 8px;
    justify-content: flex-start;
  }
  .item-column-list-default{
    margin-top: 0.4rem;
  }
}
.container-list-actions i{
  cursor: pointer;
}
.container-primary-columns-list{
  color: var(--primaryText);
}
.container-secondary-columns-list{
  color: var(--secondaryText);
}

.btn-load-more-list{
  display: block;
  color: var(--secondaryText);
  cursor: pointer;
  font-size: 0.8rem;
  width: 5rem;
}
.btn-load-more-list:hover{
  background-color: transparent;
  
}

/* Pagination */
.dark .page-link {
  background-color: var(--backgroundColor);
  border: 1px solid var(--fieldBorder);
  color: var(--primaryText);
}
.dark .page-link:hover {
  background-color: var(--mainHighlightColor);
}
.dark .page-item.active .page-link {
  color: var(--lightMainColor);
  background-color: var(--lightAccentColor);
  border-color: var(--lightAccentColor);
}
.dark .page-item.disabled .page-link {
  background-color: var(--lightMainColor);
  border-color: var(--fieldBorder);
  color: var(--primaryText);
}