.user-menu {
  height: auto;
  width: 100%;
  display:flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 3px;
}

.user-menu .collapse {
  width: 100%;
  margin-top:8px;
}

.user-menu .user-name {
  width: 100%;
  overflow: hidden;
}

.user-menu .user-information {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 4px 0 0 8px;
  margin-top:9px;
  height: 38px;
  text-overflow: ellipsis;
  justify-content: flex-start;
}

.user-menu .user-avatar, .user-menu .user-name {
  display: flex;
  align-items: center;
  height: 32px;
}

.user-menu .user-name i {
  font-size: 65%;
  margin-left: 10px;
  color: var(--mainNavActiveTextColor);
}
.user-menu .divider {
  width: 100%;
}

.user-menu {
  /*box-shadow: 0px -2px 2px rgba(32,33,36,0.1);*/
}

.user-avatar {
  width: 45px;
  height: 30px;
  margin: 0 6px 0 0;
}

.item-text {
  display: flex;
  color: var(--menuTextColor);
  height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0px ;
}

.user-name .item-text {
  font-size: 80%;
}

.user-options {
  display:inline-block;
  width:100%;
  flex-direction: column;
}

.user-options.collapsed {
  display:none;
}

.user-options .side-list {
  padding: 0 0 0 0px;
  margin: 0 0 0 0;
}

.avatar {
  font-size:13px !important;
}
.avatar > i {
  color: var(--lightMainColor);
}
.avatar > span {
  color: var(--textColor);
}
