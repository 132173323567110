 .login a,
.login a:visited, 
.login span:visited,
.login span {
    color: #7975f7;
}

.login a:hover, 
.login span:hover {
    color: #4846ea;
    text-decoration: none;
}

.login {
    height: 100vh;
    background: url("../img/loginbg-xs.svg")  no-repeat top left fixed;
  background-position-y: -20px;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    /*color: #ffffff;*/
}

.loginform {
    display: flex;
    flex-direction: column;
  flex: 1;
  margin: 0 32px;
}

.loginform-container {
  flex: 1;
}

.login-logo {
  width: 140px;
  align-self: center;
  margin-bottom: 8px;
}

.loginform input {
  padding: 0.4rem 1rem;
  height: auto;
  border-radius: 0.5rem !important;
}

.loginform label {
  margin-left: 1rem;
  color: rgba(0,0,0,0.36);
  font-size: 90%;
  margin-bottom: 0.25rem;
}

.loginform .form-check-label {
  margin-left: 0;
  display: flex;
  align-items: center;
}

.loginform .form-check {
  padding-left: 0;
}

.loginform .form-group {
  margin-bottom: 0.65rem;
}

.login button {
  background-color: #7975f7;
  border-color: #7975f7;
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.08);
  padding: 0.35rem 0;
  font-weight: bold;
}

.login .btn-secondary:not(:disabled):not(.disabled):active,
.login .btn-secondary:focus {
  background-color: #5b57e3;
  border-color: #5b57e3;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.18);
}

.login .btn-secondary:hover {
  background-color: #8885f5;
  border-color: #8885f5;
}

/*.login input[type="text"],*/
/*.login input[type="password"] {*/
/*    border: none;*/
/*    border-bottom: 1px solid #70757c;*/
/*    padding: 4px;*/
/*    margin: 16px;*/
/*    width: 240px;*/
/*    color: white;*/
/*}*/

/*.login input:focus {*/
/*    outline: none;*/
/*    border-bottom: 1px solid #FFFFFF;*/
/*}*/

/*.login button:focus {*/
/*    outline: none;*/
/*}*/

/*.username {*/
/*    background: url("./img/iconuser.svg") no-repeat 98%;*/
/*    background-size: 12px;*/
/*    padding-right: 30px;*/
/*}*/

/*.password {*/
/*    background: url("./img/iconpass.svg") no-repeat 98%;*/
/*    background-size: 12px;*/
/*    padding-right: 30px;*/
/*}*/

button#login {
    width: 36px;
    height: 36px;
    border: none;
    padding: 0;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='36' height='36'><ellipse fill='%235defd6' rx='18' ry='18' cx='18' cy='18' width='100%' height='100%'/></svg>");
    background-repeat: no-repeat;
    border-radius: 50%;
    /*box-shadow: 1px 1px 5px 3px rgba(0, 0, 0, 0.16);*/
}

button#login img {
    position: relative;
    top: -2px;
    left: 1px;
}

#loginactions {
    display:flex;
    justify-content: space-between;
    width: 100%;
  font-size: 80%;
}

#register {
    font-size: 80%;
    text-align: center;
  margin-top: 16px;
  margin-bottom: 32px;
}

#altlogins {
    display: flex;
    flex-direction: column;
    margin: 0;
    font-size: 80%;
}

#altlogins p {
  margin: 0 10px;
}

#altlogins .title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: rgba(0,0,0,0.36);
}

#altlogins .line {
  flex: 1;
  border-top: 1px solid #d3d3d3;
}

.loginmethod {
  cursor: pointer;
  align-self: center;
}

@media only screen and (min-width: 768px) {
  .login {
    background: url("../img/loginbg.svg") no-repeat center center fixed;
    background-size: cover;
    align-items: center;
  }
  .loginform {
    /*margin-left: 420px;*/
    margin: 0 64px;
    max-width: 360px;
  }
  .loginform-container {
    display: flex;
    justify-content: center;
  }
  .login-logo {
    width: 220px;
  }
  .login-loading {
    width: 37px;
    height: 37px;
    color: #7975f7;
  }
  .welcome {
    color: rgba(0,0,0,0.36);
    margin: 16px 0;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  .loginform input {
    padding: 0.75rem 1rem;
  }
  .loginform label {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
  }
  .login button {
    padding: 0.75rem 0;
  }
  .login-loading {
    width: 50px;
    height: 50px;
  }
}
