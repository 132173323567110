.hover-dropdown {
  position: relative;
  display: block !important;
  width: 100%;
  height: 100%;
}

.hover-dropdown .dropbtn {
  width: 100%;
}

.user-menu .side-list li:hover {
  background: var(--navHover);
  z-index: 4 !important;
  width: 210px;
  overflow: visible;
}

.user-menu li {
  background: var(--headerBackground);
}

.hover-dropdown .dropdown-content {
  display: block;
  position: absolute;
  left: 210px;
  top: -32px;
  background-color: var(--navHighlightColor);
  width: 150px;
  height: 96px;
  z-index: 6;
}
.hover-dropdown:hover .dropdown-content {

}

.dropdown-content ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
}

.dropdown-content ul li .list-item,
.dropdown-content ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 32px;
}
.hover-dropdown .dropdown-content ul {
  background: var(--mainColor);
}
.hover-dropdown .dropdown-content ul li:hover {
  background: var(--navHover);
  width: 150px;
}
