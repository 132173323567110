.array-editor-item {
  display: flex;
  align-items: center;
  min-height: 31px;
  font-size: 85%;
  padding: 0 4px;
  border-radius: 0.25rem;

  &.editing {
    padding: 0;
    background-color: var(--tableHover);
  }

  .array-editor-value {
    flex: 1;
  }

  .array-editor-item-actions {
    display: none;

    button {
      margin-left: 4px;
    }
  }

  &:hover {
    background-color: var(--tableHover);
    .array-editor-item-actions {
      display: block;
    }
  }
}
