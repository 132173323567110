.breadcrumb {
  display: none;
}

/* desktop */
@media only screen and (min-width: 65.875em) {
  .breadcrumb {
    display: flex;
    background-color: transparent;
    width: auto;
    height: 50px;
    margin-bottom: 0px;
    font-size: 0.9em;
    font-family: "Source Sans Pro";
    padding:0 0 0 0;
    margin-left: 15px;
    margin-top:10px;
  }

  .breadcrumb > .breadcrumb-item {
    align-items: flex-start;
  }

  .breadcrumb > .active a,
  .breadcrumb > .active {
    color: var(--accentColor);
    font-weight: bold;
    align-items: flex-start;
  }
}
