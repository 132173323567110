.app > .side-menu {
  background-color: var(--headerBackground);
}

.app .side-menu {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
  z-index: 3;
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Sans', sans-serif;
  overflow: visible;
  padding: 15px 0 0 0;
}

.app .side-menu.uncollapsed {
  will-change: transform, align-item;
  width: 210px;
  transform: translateX(0);
}

.side-menu {
  width: 46px;
  transform: translateX(0);
  transition: all .6s ease-in-out;
  height: 100vh;
}

.side-menu .list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.side-menu .list-container {
  overflow-x: visible;
  overflow-y: hidden;
}
.side-menu .list-container:hover {
  overflow-x: visible;
  overflow-y: auto;
}

.side-menu.uncollapsed .list-container {
  border-right: 10px solid transparent
}
.list-container {
  width: 10em;
  overflow: auto;
}

.list-container::-webkit-scrollbar {
  display: none;
}
/*

.scrollbar-near-moon::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px; }

.scrollbar-near-moon::-webkit-scrollbar {
  width: 12px;
  background-color: var(--headerBackground); }

.scrollbar-near-moon::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#5ee7df), to(#b490ca));
  background-image: -webkit-linear-gradient(bottom, #5ee7df 0%, #b490ca 100%);
  background-image: linear-gradient(to top, #5ee7df 0%, #b490ca 100%); }

.thin::-webkit-scrollbar {
  width: 4px;
  scrollbar-width: none;
}
*/

.side-menu .side-list {
  list-style-type: none;
  padding: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  width:100%;
}

.side-menu li {
  padding: 4px 10px;
  font-size: 85%;
  display: flex;
  height: 32px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: flex-start;
}

.side-menu li.active {
  border-right: 2px solid;
}

.side-menu li i {
  font-size: 100%;
  margin-right: 2px;
  width: 40px;
}

.side-menu li:hover {
  cursor: pointer;
}

.side-menu .divider {
  height: 1px;
  display: flex;
  width: 80%;
  text-align: center;
}

.side-menu .divider.full {
  width: 100%;
  margin: 8px 0 8px 0;
}

.side-menu li {
  color: var(--navTextColor);
}

.side-menu li.active {
  border-right-color: var(--navActiveBorder);
  background-color: var(--navActive);
  color: var(--navActiveTextColor);
  font-weight: bold;
}

.side-menu li:hover {
  background-color: var(--mainNavHover);
}

.side-menu .divider {
  background-color: var(--menuTextColor);
  align-self: center;
}

.side-menu li a {
  text-decoration: none;
  margin-left: 7px;
}

.side-menu li a a {
  margin-left: 0;
}

.side-menu li,
.side-menu li a {
  color: var(--menuTextColor);
}

.side-menu li i {
  color: var(--mainNavActiveTextColor);
}

.side-menu .mainitem {
  width: 100%;
  display: flex;
}

.side-menu .mainitem i {
  display:flex;
  align-items: center;
  height: 22px;
  margin-right: 18px;
  width: 20px;
}

.side-list {
  margin-top: 16px;
}
.side-list li a {
  display: flex;
  width: 100%;
}

.side-list .item-text {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 22px;
  width: 100%;
  text-align: left;
  align-items: flex-start;
}

.sidebar-footer {
  display: none;
}
.sidebar-footer {
  width: 100%;
  height: 32px;
  align-items: center;
  display: none;
}
.sidebar-footer-md i,
.sidebar-footer i {
  font-size: 100%;
  color: var(--mainNavActiveTextColor);
  width: 20px;
  text-align: center;
  margin-right: 14px;
}

.sidebar-footer span {
  width: 100%;
  display: flex;
}

.sidebar-footer-md span {
  width: 100%;
  display: flex;
}

.sidebar-footer-md {
  display: flex;
  width: 100%;
  height: 32px;
  align-items: center;
}

.logo-icon,
.logo-icon img {
  display: flex;
  align-items: flex-start;
  width: 42px;
  height: 32px;
  flex-direction: column;
  padding: 0 2px 0 2px;
  margin: 0 0 0 0;
  object-fit: scale-down;
  overflow: hidden;
}
/*
.logo-icon {
  align-self: center;
}*/

.logo {
  display:none;
  align-self: center;
}

.uncollapsed .logo-icon {
  display: flex;
  width: 100%;
  padding: 0 0 0 10px;
  margin: 0 0 0 0;
  height: 32px;
}

.uncollapsed .logo-icon img {
  display: flex;
  width: 120px;
  height: 32px;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}

img.logo-cover {
  object-fit: scale-down;
}

.logo h3 {
  font-family: sans-serif;
  text-transform: capitalize;
  font-style: italic;
  color: white;
  flex: 1;
  margin: 0;
}

.pageheader .title  {
  margin-top: 10px;
  font-size: 1.2rem;
  opacity: 0.6;
  font-family: 'Source Sans Pro';
  font-weight: bold;
}

.pageheader .title i {
  margin: 0 10px 0 30px;
  font-size: 1.2rem;
  color: var(--headerBackground);
}
/*
.page-content {
  max-height: 100%;
  background: red;
}

.fullscreen {
  overflow: hidden;
  height: auto;
}*/
/* medium and desktop */
@media only screen and (max-width: 768px) {
  .block-only-desktop {
    display:none;
  }
}

/* desktop */
@media only screen and (min-width: 65.875em) {
  /*.app {*/
  /*  grid-template-columns: 188px 1fr;*/
  /*  grid-template-areas:*/
  /*    "header header"*/
  /*    "sidenav main";*/
  /*}*/


  .app .side-menu {
    will-change: transform, align-item;
    width: 210px;
    transform: translateX(0);
  }

  .app .side-menu.collapsed {
    width: 46px;
  }

  .list-container::-webkit-scrollbar {
    display: flex;
  }
/*
  .collapsed .list-container::-webkit-scrollbar {
    display: flex;
    width: 0;
    height: 0;
  }*/

  .side-menu .list-container {
    border-right: 10px solid transparent
  }

  .side-menu.collapsed .list-container {
    border-right: 0;
  }
  .items-collapsed .item-text {
    display: none;
  }
  .sidebar-footer {
    display: flex;
  }

  .sidebar-footer {
    margin-right: 8px;
    display:flex;
  }

  .sidebar-footer-md {
    display: none;
  }

  .collapsed  .sidebar-footer i {
    margin-right: 14px; /*deberia centrarce nada mas*/
  }

  .logo {
    display: flex;
    width: 100%;
    padding: 0 0 0 10px;
    margin: 0 0 0 0;
    height: 32px;
  }

  .logo img {
    display: flex;
    width: 120px;
    height: 32px;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    overflow: hidden;
  }

  .collapsed .logo,
  .collapsed .logo img {
    width: 42px;
    height: 32px;
    margin: 0 0 0 0;
    padding: 0 2px 0 2px;
    object-fit: scale-down;
  }
  .uncollapsed .logo-icon,
  .logo-icon {
    display: none;
  }

  .bottom-navigation {
    display:none
  }

  .pageheader .title  {
    margin-top: 0px;
  }

  .pageheader .title i {
    margin: 0 10px 0 0;
  }

  /*.sidenav {
    position: relative;
    transform: translateX(0);
  }*/
}
