.overflow-menu-dropdown .dropdown-menu {
  background-color: var(--lightMainColor);
  top: 38px !important;
  border-radius: 0;
  transform: translate3d(-250px, 0px, 0px) !important;
  width: 320px !important;
  max-height: 600px !important;
  overflow: auto;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.overflow-menu-dropdown .dropdown-menu span,
.overflow-menu-dropdown .dropdown-menu i {
  color: var(--primaryText);
}

.overflow-menu-dropdown .dropdown-menu span {
  font-weight:lighter;
}

.overflow-menu-dropdown .dropdown-menu i {
  margin: 15px 25px 0 0;
}

.overflow-menu-dropdown .dropdown-menu .user-avatar i {
  margin: 0px 0px 0 0;
}

.user-data-dropdown-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-data-dropdown-header .user-info {
  flex-direction: column;
}

.user-info .item-text {
  margin-left: 12px;
}

.user-info .item-text.name {
  font-weight: bolder;
}

.user-data-dropdown-header  .item-text {
  color: var(--primaryText);
  font-size: 0.8em;
}

.sub-list {
  width: 100%;
  display: flex;
}

.sub-list ul,
.sub-list ul li {
  list-style: none;
}
.sub-list ul{
  margin: 0 0 0 25px;
}

.sub-list ul li {
  display:flex;
  align-items: baseline;
}

.sub-list ul li i,
.sub-list ul li span {
  display: inline-flex;
  font-size: 0.8rem !important;
}
