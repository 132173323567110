.base-dropdown .dropdown-menu {
  background: var(--lightMainColor) !important;
  border-radius: 0;
  transform: translate3d(-20px, 21px, 0px) !important;
  width: 205px !important;
  max-height: 300px !important;
  overflow: hidden;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  opacity:1 !important;
}

.dropdown-item:hover i,
.dropdown-item:hover span {
  color: var(--menuItemHighlightText);
}

.dropdown-item:hover {
  background-color: var(--primaryBackgroundColor);
}

.base-dropdown .dropdown-menu span,
.base-dropdown .dropdown-menu i {
  color: var(--primaryText);
  font-size: 0.8rem;
}

.base-dropdown .dropdown-menu span {
  font-weight:lighter;
}
.base-dropdown .dropdown-menu i {
  margin: 15px 25px 0 0 !important;
}

.base-dropdown a:hover {
  text-decoration: none !important;
}
