.navigator-list.base-list {
  width: calc(100% - 20px);
  overflow: visible;
}

.navigator-list .base-list-item .item-select {
  width: 32px;
  margin-left: 20px;
  color: var(--menuIconColor);
}

.navigator-list .base-list-item .item-name {
  margin-left: 10px;
  width: 100%;
  cursor: default;
}
