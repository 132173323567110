.help-center {
  display:none;
}

@media screen and (min-width: 768px) {
  .help-center {
    position: absolute;
    display: block;
    right: 0px;
    bottom: 0px;
    z-index: 3;
  }

  .help-center.minimized {
    width: 210px;
    height: 40px;
    border-radius: 4px 4px 0 0;
  }

  .help-center.maximized {
    width: 350px;
    height: 85vh;
    background: var(--primaryBackgroundColor);
    border-left: 1px solid var(--borderCard);
    padding-bottom: 40px;
  }

  .help-center.closed {
    display: none;
  }

  .help-center > .help-center-button {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 210px;
    height: 40px;
    font-size: 0.8rem;
    border: 0px;
    border-radius: 4px 4px 0 0;
    color: var(--buttonMainTextColor);
    background: var(--accentColor);
    z-index: 2;
  }
  .help-center > .help-center-button:focus {
    box-shadow: none;
  }

  .help-center > .help-center-button i {
    font-size: 0.8rem;
    width: 1.2rem;
    height: 1.2rem;
    border: 2px solid var(--buttonMainTextColor);
    border-radius: 50%;
    padding-top: 0.15rem;
  }

  .help-center > .help-center-card {
    position: relative;
    width: 100%;
    max-height: 85vh;
    overflow: hidden;
  }
  .help-center.minimized > .help-center-card {
    display: none;
  }

  .help-center .detail-card dl > a {
    color: var(--accentColor);
  }

  .help-center .detail-card dl dd a,
  .help-center .detail-card dl dt,
  .help-center .detail-card dl dd {
    color: var(--primaryText);
  }

  .help-center label,
  .help-center .detail-card dl dd {
    opacity: 0.8;
  }

  .help-center .detail-card dl dd:hover {
    color: var(--accentColor);
  }

  .help-center .detail-card dl dd > a:hover {
    color: var(--accentColor);
    text-decoration: none;
  }

  .help-center .detail-card > .card-header {
    padding: 0 0 0 0;
  }

  .help-center-header {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 38px;
    padding-bottom: 42px;
  }

  .help-center-header .logo {
    justify-content: center;
  }

  .help-center-header h5 {
    color: var(--mainNavActiveTextColor);
    margin-top: 4px;
  }

  .search-input-container {
    background: var(--primaryBackgroundColor);
    margin-top: 15px;
    padding-left: 24px;
  }

  .search-input-container input {
    border: none;
    height: 38px;
  }

  .search-input-container input:focus {
    outline: none;
  }

  .search-input-container i {
    opacity: 0.6 ;
  }

  .help-center .detail-card {
    border:0px;
  }

  .help-center .detail-card .card-body {
    max-height: calc(85vh - 200px);
    padding-bottom: 60px;
  }
}
