.item-dropdown .dropdown-menu {
  background: var(--lightMainColor) !important;
  top: 5px !important;
  border-radius: 0;
  transform: translate3d(-20px, 21px, 0px) !important;
  max-height: 300px !important;
  overflow: hidden;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  opacity:1 !important;
}
.folder-item .dropdown-menu.dropdown-menu-right.show,
.link-item .dropdown-menu.dropdown-menu-right.show {
  display: none;
}
.folder-item:hover .dropdown-menu.dropdown-menu-right.show,
.link-item:hover .dropdown-menu.dropdown-menu-right.show {
  display: block;
}
.folder-item:hover .dropdown-menu .dropdown-menu .dropdown-menu-right .show,
.link-item:hover .dropdown-menu .dropdown-menu .dropdown-menu-right .show {
  opacity: 1;
  z-index: 1000;
}

.item-dropdown .dropdown-item {
  opacity:1 !important;
}

.item-dropdown .dropdown-menu span,
.item-dropdown .dropdown-menu i {
  color: var(--primaryText);
  font-size: 0.8rem;
}

.item-dropdown .dropdown-menu span {
  font-weight:lighter;
}
.item-dropdown .dropdown-menu i {
  margin: 15px 25px 0 0 !important;
}
.dropdown-menu .dropdown-item:hover{
  transition: all .3s ease-in-out;
  cursor: 'pointer';
  background-color: var(--accentColor);
}
