.table-component {
  display: inline-block;
  border-spacing: 0;
  font-size: 14px;
  background-color: var(--tableContainer) !important;
  position: absolute;
  height: 100%;
  color: var(--tableText);

  .th {
    padding: 0.25rem 0;
    vertical-align: bottom;
    border-bottom: 1px solid #999;
    display: table-cell;
    font-weight: bold;
    color: var(--tableHeader);
  }

  .td {
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: var(--tableStripe);

    &.even {
      background-color: transparent;
    }
  }

  .ReactVirtualized__Table {
    .ReactVirtualized__Table__headerRow {
      border-bottom: 1px solid #999;
    }
    .ReactVirtualized__Table__headerColumn {
      text-transform: none;
      color: var(--tableHeader);
    }
    .ReactVirtualized__Table__rowColumn:first-of-type,
    .ReactVirtualized__Table__headerColumn:first-of-type {
      margin-left: 0;
    }
    .ReactVirtualized__Table__row {
      background-color: var(--tableStripe);
      &.even {
        background-color: transparent;
      }
    }
  }
}
